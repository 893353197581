body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Millimetre;
  src: url("./fonts/Millimetre-Regular.otf") format("opentype");
}

@font-face {
  font-family: Millimetre;
  font-weight: bold;
  src: url("./fonts/Millimetre-Bold.otf") format("opentype");
}

html {background-color:#150578}
*{
  color: #f2ab0d;
  font-family: 'Roboto Mono';

}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}


li:hover {
  text-decoration: underline;
  text-decoration-color: #f2ab0d;
}